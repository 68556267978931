<template>
  <div>
    <company-form />
  </div>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import CompanyForm from '@/views/client/user/components/CompanyForm.vue'

export default {
  name: 'GeneralData',
  components: {
    BLink,
    CompanyForm
  },
  data() {
    return {
      company: {}
    }
  },
  async mounted () {
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/pages/app-file-manager";
</style>